import { Stack, Typography, Divider, Skeleton, useMediaQuery, IconButton, Tooltip } from "@mui/material";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Row, ProductImage, ProductName, Container, ProductCard, TitleWrapperLimitLines } from "./style";
import { DetailContainer, DetailInfoTitle, DetailInfoValue } from '../AnnouncementItem/AnnouncementItemFurtherInfo.styles';
import { translateShopeeStatus } from "../order-header/product-info-further";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export const OrderItem = ({ activeChat, loading, value, isLast, conversationType, setSelectedId, messageId, setOpen }) => {
  const smallSize = useMediaQuery('(max-width:414px)');

  const rawValue =  value?.unit_price ?? value?.item?.unit_price ?? value?.item_price ?? value?.price ?? value?.total_amount ?? 0;

  const parsedValue = typeof rawValue === "string"
                      ? parseFloat(rawValue.replace(",", "."))
                      : rawValue;

  const unitPrice = (parsedValue || 0).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  const attributes = value?.item?.variation_attributes || value?.variation_attributes || [];
  
  const redirectToItemMessage = messageId || value?.message_id;

  const handleIconClick = () => {
    const id = redirectToItemMessage;
    if (id) {
      setSelectedId(id); 
      setOpen(false);
    }
  };

  const formatDate = (dateString) => {    
    const createdAt = new Date(dateString);  
    return format(createdAt, "dd/MM/yyyy hh:mm", { locale: ptBR });
  };

  return (
    <>
      {loading ? (
        <>
          <Row alignItems="start">
            <Row alignItems="start">
              <Skeleton variant="rounded" width="4rem" height="4rem" />
              <Stack>
                <Typography variant="subtitle1">
                  <Skeleton width={100} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton width={70} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton width={70} />
                </Typography>
              </Stack>
            </Row>

            <Stack alignItems="flex-end" py={1} spacing={0.5} pr="0.5rem">
              <Skeleton width={70} />
              <Skeleton width={30} />
            </Stack>
          </Row>
          {!isLast && (
            <Divider
              sx={{
                marginTop: 1,
                marginBottom: 1,
                marginRight: 1,
              }}
            />
          )}
        </>
      ) : (
        <>
          <Container>
            <ProductCard>
              <Row alignItems="start" sx={{ gap: '2.2rem', justifyContent: "space-between", width: '100%' }}>
                <Row alignItems="start" justifyContent="start">
                  <ProductImage 
                    size={smallSize ? "50px" : "90px"} 
                    src={value?.item?.thumbnail || value?.thumbnail || value?.image_url || value?.order_items?.[0]?.announcements_image_url} 
                    alt="Product Image" 
                  />
                  <Stack sx={{ flex: 1 }}>
                    <TitleWrapperLimitLines>
                      {value?.item?.link || value?.announcement_url || value?.order_items?.[0]?.announcement_link ? (
                        <ProductName href={value?.item?.link || value?.announcement_url || value?.order_items?.[0]?.announcement_link} target="_blank">
                          {value?.item?.title || value?.title || value?.order_items?.[0]?.title}
                        </ProductName>
                      ) : (
                        <Typography>{value?.item?.title || value?.title || value?.order_items?.[0]?.title}</Typography>
                      )}
                    </TitleWrapperLimitLines>
                    <DetailContainer>
                      <>
                        {value?.order_items?.[0] && (
                          <div style={{ display: "flex" }}>
                            <DetailInfoTitle>Cód pedido:</DetailInfoTitle>
                            <DetailInfoValue>{value?.platform_order_id}</DetailInfoValue>
                          </div>
                        )}
                        <div style={{ display: "flex" }}>
                          <DetailInfoTitle>Cód anuncio:</DetailInfoTitle>
                          <DetailInfoValue>
                            {value?.item?.id || value?.item_id || value?.announcement_id || value?.order_items?.[0]?.marketplace_announcements_id}
                          </DetailInfoValue>
                        </div>
                      </>
                    </DetailContainer>
                    {(value?.order_items?.[0] && conversationType === "chats") ? (
                      <>
                        <DetailContainer>
                          <DetailInfoTitle>Status:</DetailInfoTitle>
                          <DetailInfoValue>{translateShopeeStatus(value?.status)}</DetailInfoValue>
                        </DetailContainer>

                        <DetailContainer>
                          <DetailInfoTitle>Data do Pedido:</DetailInfoTitle>
                          <DetailInfoValue>
                            {formatDate(value?.order_items?.[0]?.createdAt)}
                          </DetailInfoValue>
                        </DetailContainer>
                      </>
                    ) : (
                      <DetailContainer>
                        <DetailInfoTitle>Categoria:</DetailInfoTitle>
                        <DetailInfoValue>
                          {value?.item?.category_id || value?.category_id || value?.variation_sku}
                        </DetailInfoValue>
                      </DetailContainer>
                    )}                              
                    {attributes.map((attribute, index) => (
                      <DetailContainer key={index}>
                        <DetailInfoTitle>{`${attribute?.name}:`}</DetailInfoTitle>
                        <DetailInfoValue>{`${attribute?.value_name}`}</DetailInfoValue>
                      </DetailContainer>
                    ))}
                      {
                        value?.item?.catalog_listing && (
                          <DetailContainer>
                            <DetailInfoTitle>Item de Catálogo</DetailInfoTitle>
                          </DetailContainer>
                        )
                      }
                  </Stack>
                </Row>
                <Stack alignItems="flex-end" py={1} spacing={0.5}>
                  <Stack
                    sx={{
                      backgroundColor: "primary.main",
                      padding: "1px 5px 1px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="subtitle1" color="primary.contrastText">
                      {unitPrice}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle1">{`x${value?.quantity || 1}`}</Typography>
                  
                  {conversationType === "chats" && (
                    <Tooltip title="Localizar na conversa">
                      <IconButton onClick={handleIconClick}>
                        <ReplyAllIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Row>
            </ProductCard>
          </Container>
          {!isLast && (
            <Divider
              sx={{
                marginTop: 1,
                marginBottom: 1,
                marginRight: 1,
              }}
            />
          )}
        </>
      )}
    </>
  );
};
